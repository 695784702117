import React, { useState, useEffect } from "react";
import axios from "axios";
import CountrySelectionR16 from "../components/countrySelectR16";

export default function Round16() {
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    axios
      .get("https://bkcountdown.com/nodeapp/getQuarterCountries")
      .then((res) => {
        setCountries(res.data);
      })
      .catch((err) => {
        throw err;
      });
  }, []);

  const [groupA, setGroupA] = useState([]);
  useEffect(() => {
    axios
      .get("https://bkcountdown.com/nodeapp/getGroupA")
      .then((res) => {
        setGroupA(res.data);
      })
      .catch((err) => {
        throw err;
      });
  }, []);

  const [groupB, setGroupB] = useState([]);
  useEffect(() => {
    axios
      .get("https://bkcountdown.com/nodeapp/getGroupB")
      .then((res) => {
        setGroupB(res.data);
      })
      .catch((err) => {
        throw err;
      });
  }, []);

  const [groupC, setGroupC] = useState([]);
  useEffect(() => {
    axios
      .get("https://bkcountdown.com/nodeapp/getGroupC")
      .then((res) => {
        setGroupC(res.data);
      })
      .catch((err) => {
        throw err;
      });
  }, []);

  const [groupD, setGroupD] = useState([]);
  useEffect(() => {
    axios
      .get("https://bkcountdown.com/nodeapp/getGroupD")
      .then((res) => {
        setGroupD(res.data);
      })
      .catch((err) => {
        throw err;
      });
  }, []);

  const [groupE, setGroupE] = useState([]);
  useEffect(() => {
    axios
      .get("https://bkcountdown.com/nodeapp/getGroupE")
      .then((res) => {
        setGroupE(res.data);
      })
      .catch((err) => {
        throw err;
      });
  }, []);

  const [groupF, setGroupF] = useState([]);
  useEffect(() => {
    axios
      .get("https://bkcountdown.com/nodeapp/getGroupF")
      .then((res) => {
        setGroupF(res.data);
      })
      .catch((err) => {
        throw err;
      });
  }, []);

  const [groupG, setGroupG] = useState([]);
  useEffect(() => {
    axios
      .get("https://bkcountdown.com/nodeapp/getGroupG")
      .then((res) => {
        setGroupG(res.data);
      })
      .catch((err) => {
        throw err;
      });
  }, []);

  const [groupH, setGroupH] = useState([]);
  useEffect(() => {
    axios
      .get("https://bkcountdown.com/nodeapp/getGroupH")
      .then((res) => {
        setGroupH(res.data);
      })
      .catch((err) => {
        throw err;
      });
  }, []);

  return (
    <div class="accordion" id="accordionExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingOne">
          <button
            class="accordion-button "
            type="button"
            // data-bs-toggle="collapse"
            // data-bs-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
          >
            Vòng 16 đội
          </button>
        </h2>
        <div
          id="collapseOne"
          class="accordion-collapse collapse show"
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body">
            <div className="countryPredictionContainer">
              <div class="row">
                <div class="col-md-6">
                  <CountrySelectionR16
                    group1="A"
                    group2="B"
                    zIndex1="99"
                    zIndex2="98"
                    zIndex3="97"
                    id1="1A"
                    id2="2B"
                    countries1={groupA}
                    countries2={groupB}
                  />
                  <CountrySelectionR16
                    group1="C"
                    group2="D"
                    zIndex1="96"
                    zIndex2="95"
                    zIndex3="94"
                    id1="1C"
                    id2="2D"
                    countries1={groupC}
                    countries2={groupD}
                  />
                  <CountrySelectionR16
                    group1="B"
                    group2="A"
                    zIndex1="93"
                    zIndex2="92"
                    zIndex3="91"
                    id1="1B"
                    id2="2A"
                    countries1={groupB}
                    countries2={groupA}
                  />
                  <CountrySelectionR16
                    group1="D"
                    group2="C"
                    zIndex1="90"
                    zIndex2="89"
                    zIndex3="88"
                    id1="1D"
                    id2="2C"
                    countries1={groupD}
                    countries2={groupC}
                  />
                </div>
                <div class="col-md-6">
                  {" "}
                  <CountrySelectionR16
                    group1="G"
                    group2="H"
                    zIndex1="87"
                    zIndex2="86"
                    zIndex3="85"
                    id1="1G"
                    id2="2H"
                    countries1={groupG}
                    countries2={groupH}
                  />
                  <CountrySelectionR16
                    group1="E"
                    group2="F"
                    zIndex1="84"
                    zIndex2="83"
                    zIndex3="82"
                    id1="1E"
                    id2="2F"
                    countries1={groupE}
                    countries2={groupF}
                  />
                  <CountrySelectionR16
                    group1="H"
                    group2="G"
                    zIndex1="81"
                    zIndex2="80"
                    zIndex3="79"
                    id1="1H"
                    id2="2G"
                    countries1={groupH}
                    countries2={groupG}
                  />
                  <CountrySelectionR16
                    group1="F"
                    group2="E"
                    zIndex1="78"
                    zIndex2="77"
                    zIndex3="76"
                    id1="1F"
                    id2="2E"
                    countries1={groupF}
                    countries2={groupE}
                  />
                </div>
              </div>
              Mỗi dự đoán đúng = 2 Điểm, Tối đa 16 Điểm
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
