import React from "react";
import $ from "jquery";
import "../css/header.css";

export default function header() {
  function showDateTime() {
    var today = new Date();
    var date =
      today.getDate() +
      "/" +
      (today.getMonth() + 1) +
      "/" +
      today.getFullYear();
    var time =
      today.getHours() +
      ":" +
      String(today.getMinutes()).padStart(2, "0") +
      ":" +
      today.getSeconds();
    var dateTime = date + " " + time + " " + "(GMT+8)";

    $("#dateTime").html(dateTime);
  }

  setInterval(showDateTime, 1000);

  return (
    <div className="header">
      <div className="container">
        <div className="logo">
          <img src=".\assets\BK8-worldcup-logo-01.svg" alt="" />
        </div>

        <div className="loginContainer">
          <div className="dateTime" id="dateTime"></div>
          <div className="login">
            <span>
              <a
                href="https://www.bk8p.com/login?affid=24189"
                target="_blank"
                id="loginBtn"
                className="loginBtn"
              >
                ĐĂNG NHẬP
              </a>
            </span>
            <span>
              <button
                className="registerBtn"
                onClick={() => {
                  window.open("https://www.bk8p.com/register?affid=24189");
                }}
              >
                ĐĂNG KÝ
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
