import React, { useEffect, useState } from "react";
import "../css/landing.css";
import Round16 from "./round16";
import QuarterFinals from "./quarterFinals";
import SemiFinals from "./semiFinals";
import Final from "./final";
import axios from "axios";
import DiamondShape from "../widgets/diamondShape";
import R16Submission from "../functions/R16_Submission";
import QuarterFinals_Submission from "../functions/QuarterFinals_Submission";
import SemiFinals_Submission from "../functions/SemiFinals_Submission";
import Final_Submission from "../functions/Final_Submission";

export default function Landing() {
  const [match, setMatch] = useState([]);
  useEffect(() => {
    axios
      .get("https://bkcountdown.com/nodeapp/getCurrentMatch")
      .then((res) => {
        console.log(res);
        setMatch(res.data[0].match);
      })
      .catch((err) => {
        throw err;
      });
  }, []);

  if (match == "QuarterFinals") {
    var submission = QuarterFinals_Submission;
  } else if (match == "SemiFinals") {
    var submission = SemiFinals_Submission;
  } else if (match == "Final") {
    var submission = Final_Submission;
  } else {
    var submission = R16Submission;
  }

  return (
    <div className="landing">
      <div className="container">
        <img src="./assets/fifa2022.png" alt="fifa2022" className="fifa2022" />{" "}
        <div class="countdownTitle web">
          <DiamondShape height="10" width="10" />
          <DiamondShape height="15" width="15" />
          Dự đoán nhà vô địch World Cup 2022
          <DiamondShape height="15" width="15" />
          <DiamondShape height="10" width="10" />
        </div>
        <div class="countdownTitle mobile">
          <DiamondShape height="10" width="10" />
          <DiamondShape height="15" width="15" />
          Dự đoán đội
          <DiamondShape height="15" width="15" />
          <DiamondShape height="10" width="10" />
        </div>
        {match == "R16" ? <Round16 /> : ""}
        {match == "QuarterFinals" ? <QuarterFinals /> : ""}
        {match == "SemiFinals" ? <SemiFinals /> : ""}
        {match == "Final" ? <Final /> : ""}
        <p className="emailText">
          Nhập địa chỉ Email của bạn để nhận báo cáo dự đoán
        </p>
        <input
          className="usernameInput"
          id="emailInput"
          type="email"
          placeholder="Nhập địa chỉ Email của bạn"
        />
        <div className="usernameContainer">
          <p>Nếu bạn đã là thành viên</p>
          <div className="usernameSubContainer">
            {" "}
            <input
              className="usernameInput"
              id="usernameInput"
              type="text"
              placeholder="NHẬP TÊN TRUY CẬP CỦA BẠN"
            />
            <div className="container submitBtnWrapper">
              <div
                className="submitBtnContainer"
                onClick={submission}
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                <img src="./assets/button-01.svg" alt="" />
                <p>GỬI DỰ ĐOÁN</p>
              </div>
            </div>{" "}
          </div>

          <p>Nếu bạn chưa phải là thành viên</p>
          <div className="usernameSubContainer">
            <div className="">
              <input
                className="phone_number"
                id="phone_number"
                type="number"
                placeholder="NHẬP SỐ ĐIỆN THOẠI CỦA BẠN"
              />
            </div>
            <div className="">
              {" "}
              <button
                className="registerNowBtn"
                onClick={submission}
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                Gửi & Đăng ký ngay
              </button>
            </div>
          </div>
        </div>
        {/* Modal */}
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body" id="modal_body"></div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Đóng
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
