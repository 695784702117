import React from "react";
import $ from "jquery";
import "../css/tnc.css";

export default function tnc() {
  function triggerHowToApply() {
    $("#howToApplyContainer").show();
    $("#termConditionContainer").hide();
    $("#eventDetailsContainer").hide();
    $("#howToApplyBtn").css("background-color", "#8a1538");
    $("#termConditionBtn").css("background-color", "transparent");
    $("#eventDetailsBtn").css("background-color", "transparent");
  }

  function triggerTermsCondition() {
    $("#howToApplyContainer").hide();
    $("#termConditionContainer").show();
    $("#eventDetailsContainer").hide();
    $("#howToApplyBtn").css("background-color", "transparent");
    $("#termConditionBtn").css("background-color", "#8a1538");
    $("#eventDetailsBtn").css("background-color", "transparent");
  }

  function triggerEventDetails() {
    $("#howToApplyContainer").hide();
    $("#termConditionContainer").hide();
    $("#eventDetailsContainer").css("display", "flex");
    $("#howToApplyBtn").css("background-color", "transparent");
    $("#termConditionBtn").css("background-color", "transparent");
    $("#eventDetailsBtn").css("background-color", "#8a1538");
  }

  return (
    <div className="tnc">
      <div className="container">
        <button
          className="eventDetailsBtn"
          id="eventDetailsBtn"
          onClick={triggerEventDetails}
        >
          Thông tin chương trình
        </button>
        <button
          className="howToApplyBtn"
          id="howToApplyBtn"
          onClick={triggerHowToApply}
        >
          Cách thức tham gia
        </button>
        <button
          className="termConditionBtn"
          id="termConditionBtn"
          onClick={triggerTermsCondition}
        >
          Điều khoản và điều kiện
        </button>

        <div className="howToApplyContainer" id="howToApplyContainer">
          <ol>
            <li>
              Chỉ áp dụng cho các tài khoản thành viên VND có tổng đơn cược tối
              thiểu VND 500 tại các nhà cung cấp Thể Thao trên website trong
              suốt thời gian diễn ra giải đấu.
            </li>
            <li>
              Trên trang Ưu Đãi, chọn mục khuyến mãi “Dự Đoán Đội Vô Địch World
              Cup 2022” nhấn vào [Tham gia Ngay]. Trên trang dự đoán, chọn tên
              đội bóng bạn dự đoán từ danh sách trận đấu, điền thông tin yêu cầu
              và gửi kết quả dự đoán.
            </li>
            <li>
              Xếp hạng sẽ dựa trên tổng số điểm tích lũy cao nhất của tất cả
              thành viên tham gia.
            </li>
            <li>
              Thành viên có thể xem lịch sử dự đoán qua kiểm tra email đăng ký
              trên trang dự đoán.
            </li>
            <li>
              Kết quả dự đoán sẽ dựa trên lần gửi dự đoán cuối cùng và trước mỗi
              vòng đấu. Không giới hạn số lần gửi phiếu dự đoán.
            </li>
            <li>
              Số lượng 100 giải thưởng dành cho những dự đoán có điểm tích lũy
              cao nhất và thời gian gửi phiếu dự đoán sớm nhất.
            </li>
            <li>
              Danh sách thành viên chiến thắng sẽ được cập nhật trên trang
              Khuyến Mãi BK8 &gt; Cột Người Chiến Thắng trong vòng bảy (7) ngày
              làm việc sau khi có kết quả.
            </li>
            <li>
              Giải thưởng sẽ được tặng trực tiếp vào tài khoản thành viên dưới
              dạng lì xì may mắn, không yêu cầu vòng cược trước khi rút, và
              thành viên trúng thưởng sẽ được thông báo qua tin nhắn.
            </li>
          </ol>
        </div>
        <div className="termConditionContainer" id="termConditionContainer">
          <ol>
            <li>
              Để tham gia khuyến mãi hợp lệ, thành viên phải chọn VND là đơn vị
              tiền tệ ưu tiên.
            </li>
            <li>
              Nếu thành viên hoặc nhóm thành viên bị phát hiện sử dụng các
              phương pháp không trung thực, gian lận hoặc đe dọa người chơi /
              nhà điều hành khác, hoặc bị phát hiện lợi dụng sơ hở hệ thống sẽ
              bị loại khỏi giải đấu.
            </li>
            <li>
              BK8 có quyền tước bỏ các giải thưởng đã trao nếu người thắng cuộc
              không tuân thủ các điều khoản và điều kiện tại đây.
            </li>
            <li>
              BK8 là trọng tài duy nhất của chương trình khuyến mại và quyết
              định của BK8 là quyết định cuối cùng.
            </li>
            <li>
              Tất cả các ưu đãi của khách hàng được giới hạn một lần cho mỗi
              người. Có nghĩa là mỗi gia đình, địa chỉ hộ gia đình, địa chỉ IP,
              địa chỉ email, số điện thoại, thẻ tín dụng hoặc thẻ ghi nợ và /
              hoặc tài khoản thanh toán điện tử, hoặc máy tính dùng chung (ví
              dụ: trường học, thư viện công cộng hoặc nơi làm việc).
            </li>
            <li>
              Tiền thưởng có giá trị trong bảy (7) ngày sau khi phát hành trừ
              khi có quy định khác. Số tiền giành được khi sử dụng quỹ thưởng sẽ
              bị xóa khỏi tài khoản của thành viên nếu các điều kiện không được
              đáp ứng trong một khung thời gian nhất định.
            </li>
            <li>
              Khuyến mãi này không được áp dụng đồng thời với các chương trình
              khuyến mãi khác của BK8.
            </li>
            <li>
              BK8 có quyền sửa đổi, hủy bỏ, tạm dừng hoặc chấm dứt chương trình
              khuyến mãi và / hoặc thay đổi các điều khoản của chương trình
              khuyến mãi nói trên bất kỳ lúc nào mà không cần thông báo trước.
            </li>
            <li>
              Các thành viên tham gia phải chấp nhận và tuân thủ tất cả các điều
              khoản được đề cập ở trên cũng như tất cả các quy tắc và quy định
              có liên quan được nêu trên trang web BK8.
            </li>
            <li>
              Lưu ý tiền tệ VND quy đổi bằng đơn vị 1000 đồng. Ví dụ VND 200 =
              200,000 đồng.
            </li>
            <li>Điều khoản & Điều kiện chung của BK8 được áp dụng.</li>
          </ol>
        </div>
        <div className="eventDetailsContainer" id="eventDetailsContainer">
          <p>Thời gian chương trình: 15/11/2022 - 18/12/2022 (GMT+8)</p>
          <table>
            <tr>
              <th>Thời gian dự đoán</th>
              <th>Giai đoạn dự đoán</th>
              <th>Điểm/dự đoán đúng</th>
              <th>Điểm tối đa</th>
            </tr>
            <tr>
              <td>
                Từ 00:00:00, 15/11/2022 - Đến 23:59:59, 19/11/2022 (GMT+8)
              </td>
              <td>Vòng 16 đội - 8 trận</td>
              <td>2</td>
              <td>16</td>
            </tr>
            <tr>
              <td>
                Từ 00:00:00, 20/11/2022 - Đến 23:59:59, 02/12/2022 (GMT+8)
              </td>
              <td>Vòng Tứ Kết – 4 trận</td>
              <td>3</td>
              <td>12</td>
            </tr>
            <tr>
              <td>
                Từ 00:00:00, 03/12/2022 - Đến 23:59:59, 08/12/2022 (GMT+8)
              </td>
              <td>Vòng Bán Kết – 2 trận</td>
              <td>4</td>
              <td>8</td>
            </tr>
            <tr>
              <td>
                Từ 00:00:00, 09/12/2022 - Đến 23:00:00, 12/12/2022 (GMT+8)
              </td>
              <td>Vòng Chung Kết – 1 trận</td>
              <td>8</td>
              <td>8</td>
            </tr>
            <tr>
              <td>Tổng cộng</td>
              <td>15 trận</td>
              <td></td>
              <td>44</td>
            </tr>
          </table>
          <p>*Giải thưởng</p>
          <table>
            <tr>
              <td>Xếp hạng</td>
              <td>Số điểm yêu cầu</td>
              <td>Giải thưởng (VND)</td>
            </tr>
            <tr>
              <td>1</td>
              <td>44 điểm</td>
              <td>8,888</td>
            </tr>
            <tr>
              <td>2</td>
              <td rowSpan={2}>Tối thiểu 40 điểm</td>
              <td>6,888</td>
            </tr>
            <tr>
              <td>3</td>
              <td>5,888</td>
            </tr>
            <tr>
              <td>4</td>
              <td rowSpan={2}>Tối thiểu 35 điểm</td>
              <td>3,888</td>
            </tr>
            <tr>
              <td>5</td>
              <td>2,888</td>
            </tr>
            <tr>
              <td>6</td>
              <td rowSpan={5}>Tối thiểu 32 điểm</td>
              <td>1,888</td>
            </tr>
            <tr>
              <td>7</td>
              <td>888</td>
            </tr>
            <tr>
              <td>8</td>
              <td>588</td>
            </tr>
            <tr>
              <td>9</td>
              <td>288</td>
            </tr>
            <tr>
              <td>10</td>
              <td>188</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
}
